import React from "react";
import { Fade } from "react-reveal";
export const GridImageSection = ({ data, handleClick,baseUrl }) => {
  return (
    <div className="grid grid-cols-2 sm:grid sm:grid-cols-1">
      {data.map((ele, i) => {
        return (
          <Fade bottom>
            <div
              className="sm:w-full cursor-pointer h-full"
              onClick={() => handleClick(ele.hqImage)}
              key={i}
            >
              <img
                src={`${baseUrl}${ele.image}`}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
          </Fade>
        );
      })}
    </div>
  );
};
