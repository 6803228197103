import React from "react";
import { Fade } from "react-reveal";

export const Subnav = ({ data, handlePage, activeIndex, parentClassName }) => {
  return (
    <div className="automobileNav">
      <Fade bottom>
        <div
          className={
            data.length===2 ? "w-fit 2xl:w-fit uppercase xl:w-fit lg:w-fit sm:w-[40%] m-auto mt-[15px] z-40 flex justify-between gap-2 lg:gap-1 rounded-[32px] sm:rounded-[0] p-[2px] sm:p-[1px] navmenu" :
            "w-fit 2xl:w-fit uppercase xl:w-fit lg:w-fit sm:w-full m-auto mt-[15px] z-40 flex justify-between gap-2 lg:gap-1 rounded-[32px] sm:rounded-[0] p-[2px] sm:p-[1px] navmenu"
          }
        >
          {data.map((ele, i) => {
            return (
              <div
                className={
                  activeIndex === i
                    ? "text-center cursor-pointer flex items-center border-[0px] pl-[18px] pr-[18px] pt-[10px] pb-[10px] rounded-[36px] bg-[#777271] text-white sm:pl-[15px] sm:pr-[15px] sm:pb-[5px] sm:pt-[5px]"
                    : "text-center border-[1px] flex items-center border-[#cec9c8] pl-[18px] pr-[18px] pt-[10px] pb-[10px] rounded-[36px] cursor-pointer bg-[#f9f9f9] sm:pl-[15px] sm:pr-[15px] sm:pb-[5px] sm:pt-[5px]"
                }
                // onClick={setCurrentPage(ele.title)}
                onClick={() => handlePage(ele.page, i)}
                key={i}
              >
                <p className="text-[16px] font-[500] lg:text-[12px] sm:text-[12px] whitespace-nowrap">
                  {ele.title}
                </p>
              </div>
            );
          })}
        </div>
      </Fade>
    </div>
  );
};
