import React, { useState } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Fade } from "react-reveal";

const images = [
  {
    image: "apollo_tyre_01.jpg",
    hqImage: "apollo_tyre_01_Hq.jpg",
  },
  {
    image: "apollo_tyre_02.jpg",
    hqImage: "apollo_tyre_02_Hq.jpg",
  },
  {
    image: "apollo_tyre_03.jpg",
    hqImage: "apollo_tyre_03_Hq.jpg",
  },
];

const modalImages = [
  "apollo_tyre_01_Hq.jpg",
  "apollo_tyre_02_Hq.jpg",
  "apollo_tyre_03_Hq.jpg",
];
export const ApolloTyre = () => {
  const imageUrl = "../images/home/lifestyle/";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);

  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div>
      {/* Text content */}
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Apollo tyre
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              {/* we create stunning graphics for the fashion and lifestyle
              industry, enhancing brand identities and marketing materials with
              style and creativity." */}
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1 mt-20 sm:mt-5">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer object-cover"
                onClick={() => handleClick(ele.hqImage)}
                key={i}
              >
                <img
                  src={`../images/home/lifestyle/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target h-full"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen} //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  );
};
