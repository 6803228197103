import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Footer } from "../../../components/Footer";
import { ArtImages } from "./images";
import { ArtVideo } from "./video";
import { CraftSubSection_1 } from "./CraftSubSection_1";
import { CraftSubSection_2 } from "./CraftSubSection_2";
import { CraftSubSection_3 } from "./CraftSubSection_3";
import { CraftSubSection_4 } from "./CraftSubSection_4";
import { CraftSubSection_5 } from "./CraftSubSection_5";
import { CraftSubSection_6 } from "./CraftSubSection_6";
import { Subnav } from "../../../components/subNav";


const footerContent = [
    { title: "ABOUT US", href: "/aboutus" },
    { title: "CONTACT", href: "/contactus" },
  ];
const techNav = [
  // {
  //   title: "IMAGES",
  //   page:<ArtImages/>,
  // },
  // {
  //   title: "VIDEO",
  //   page:<ArtVideo/>,
  // },
  {
    title: "Nirav Modi",
    page:<CraftSubSection_1/>,
  },
  {
    title: "Maserati",
    page:<CraftSubSection_2/>,
  },
  {
    title: "Ahuja Calendar",
    page:<CraftSubSection_3/>,
  },
  {
    title: "Zoya – Musee Du Luxe",
    page:<CraftSubSection_4/>,
  },
  {
    title: "Intestine",
    page:<CraftSubSection_5/>,
  },
  {
    title: "Zoya Fairytale",
    page:<CraftSubSection_6/>,
  }
];
export const Art = () => {
  const [currentPage, setCurrentPage] = useState(<CraftSubSection_1/>);
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(1378);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const handlePage = (page, index) => {
    setCurrentPage(page);
    setActiveIndex(index);
    window.scrollTo({top:0, left: 0, behavior: 'smooth'});
  };
  return (
    <div className="pt-[80px]">
      <div className="relative">
        <img
          src={
            windowWidth > 700
              ? `../images/home/art/banner.png`
              : `../images/home/art/bannerMob.png`
          }
          alt="banner"
          className="w-full brightness-[0.5]"
        />
        <Fade bottom>
          <p className="absolute uppercase leading-[108px] sm:leading-[50px] w-full text-center top-1 font-[500] text-[90px] lg:text-[50px] text-white flex items-center justify-center h-full">
           CRAFT
          </p>
        </Fade>
      </div>
      {/* tech nav */}
      <Subnav data={techNav} activeIndex={activeIndex} handlePage={handlePage}/>
      <>{currentPage}</>
      {/* Footer */}
       <Footer data={footerContent}/>
    </div>
  );
};
