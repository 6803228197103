import React, { useState } from "react";
import { Fade } from "react-reveal";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { Button, useDisclosure } from "@chakra-ui/react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BeforeAfterComponent } from "../../../components/BeforeAfter";
import { GridImageSection } from "../../../components/GridImageSection";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation} from "swiper/modules";
import { IoClose } from "react-icons/io5";


const carBeforeAfterImages = [
  {
    BeforeImage: "../images/home/automobile/shoot-image/car-before.jpg",
    HQBeforeimage: "../images/home/automobile/shoot-image/car-before-hq.jpg",
    AfterImage: "../images/home/automobile/shoot-image/car-after.jpg",
    HQAfterimage: "../images/home/automobile/shoot-image/car-after-hq.jpg",
  },
];

const carBeforeAfterImages2 = [
  {
    BeforeImage: "../images/home/automobile/shoot-image/car-2-before.jpg",
    HQBeforeimage: "../images/home/automobile/shoot-image/car-2-before-hq.jpg",
    AfterImage: "../images/home/automobile/shoot-image/car-2-after.jpg",
    HQAfterimage: "../images/home/automobile/shoot-image/car-2-after-hq.jpg",
  },
];
const carBeforeAfterImages3 = [
  {
    BeforeImage: "../images/home/automobile/shoot-image/car-3-before.jpg",
    HQBeforeimage: "../images/home/automobile/shoot-image/car-3-before-hq.jpg",
    AfterImage: "../images/home/automobile/shoot-image/car-3-after.jpg",
    HQAfterimage: "../images/home/automobile/shoot-image/car-3-after-hq.jpg",
  },
];
const BikeOneBeforeAfterImages = [
  {
    BeforeImage: "../images/home/automobile/shoot-image/bike-before-1.jpg",
    HQBeforeimage: "../images/home/automobile/shoot-image/bike-before-1-hq.jpg",
    AfterImage: "../images/home/automobile/shoot-image/bike-after-1.jpg",
    HQAfterimage: "../images/home/automobile/shoot-image/bike-after-1-hq.jpg",
  },
];

const BikeTwoBeforeAfterImages = [
  {
    BeforeImage: "../images/home/automobile/shoot-image/bike-before-2.jpg",
    HQBeforeimage: "../images/home/automobile/shoot-image/bike-before-2-hq.jpg",
    AfterImage: "../images/home/automobile/shoot-image/bike-after-2.jpg",
    HQAfterimage: "../images/home/automobile/shoot-image/bike-after-2-hq.jpg",
  },
];


const imagesFirstSection = [
  {
    image: "img1.jpg",
    hqImage: "../images/home/automobile/shoot-image/img1HQ.jpg",
  },
  {
    image: "img2.jpg",
    hqImage: "../images/home/automobile/shoot-image/img2HQ.jpg",
  },
];
const secondSection = [
  {
    image: "img3.jpg",
    hqImage: "../images/home/automobile/shoot-image/img3HQ.jpg",
  },
  {
    image: "img4.jpg",
    hqImage: "../images/home/automobile/shoot-image/img4HQ.jpg",
  },
];

const thirdSection = [
  {
    image: "img5.jpg",
    hqImage: "../images/home/automobile/shoot-image/img5HQ.jpg",
  },
  {
    image: "img6.jpg",
    hqImage: "../images/home/automobile/shoot-image/img6HQ.jpg",
  },
];
const fourthSection = [
  {
    image: "img7.jpg",
    hqImage: "../images/home/automobile/shoot-image/img7HQ.jpg",
  },
];
const modalImage = [
  "../images/home/automobile/shoot-image/intorHQ.jpg",
  "../images/home/automobile/shoot-image/car-before-hq.jpg",
  "../images/home/automobile/shoot-image/car-after-hq.jpg",
  "../images/home/automobile/shoot-image/car-2-before-hq.jpg",
  "../images/home/automobile/shoot-image/car-2-after-hq.jpg",
  "../images/home/automobile/shoot-image/car-3-before-hq.jpg",
  "../images/home/automobile/shoot-image/car-3-after-hq.jpg",
  "../images/home/automobile/shoot-image/bike-before-1-hq.jpg",
  "../images/home/automobile/shoot-image/bike-after-1-hq.jpg",
  "../images/home/automobile/shoot-image/bike-before-2-hq.jpg",
  "../images/home/automobile/shoot-image/bike-after-2-hq.jpg",
  "../images/home/automobile/shoot-image/img1HQ.jpg",
  "../images/home/automobile/shoot-image/img2HQ.jpg",
  "../images/home/automobile/shoot-image/img3HQ.jpg",
  "../images/home/automobile/shoot-image/img4HQ.jpg",
  "../images/home/automobile/shoot-image/img5HQ.jpg",
  "../images/home/automobile/shoot-image/img6HQ.jpg",
  "../images/home/automobile/shoot-image/img7HQ.jpg",
];
const NormalImageContainer = ({ data, handleClick }) => {
  return (
    <div>
      {data.map((ele, i) => {
        return (
          <Fade bottom>
            <div
              className="cursor-pointer"
              key={i}
              onClick={() => handleClick(ele.hqImage)}
            >
              <img
                src={`../images/home/automobile/shoot-image/${ele.image}`}
                alt=""
                className="w-full"
              />
            </div>
          </Fade>
        );
      })}
    </div>
  );
};

export const ShootImages = () => {
  const imageUrl = "../images/home/automobile/shoot-image/";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);

  // HQ Image On Modal
  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImage.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  const handleHomeimage = () => {
    onOpen();
    setHqImage("../images/home/automobile/shoot-image/intorHQ.jpg");
    setSpecificIndex(0);
  };
  return (
    <div className="mt-10 sm:mt-5">
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              Photography
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full lg:w-[90%] text-[#777271] lg:text-[16px] sm:text-[14px]">
              This blend of real-world authenticity and creative transformation
              allows us to deliver promotional images that resonate with your
              audience and effectively convey your brand's message
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div
            className="w-[50%] sm:w-full sm:mt-10 cursor-pointer"
            onClick={handleHomeimage}
          >
            <img
              src="../images/home/automobile/shoot-image/intro.jpg"
              alt="CGI"
              className="w-full"
            />
          </div>
        </Fade>
      </div>
      {/* Car Before After */}
      <BeforeAfterComponent
        data={carBeforeAfterImages}
        handleClick={handleClick}
      />
      {/* Bike one Before After */}
      <BeforeAfterComponent
        data={BikeOneBeforeAfterImages}
        handleClick={handleClick}
      />
      {/* Bike two Before After */}
      <BeforeAfterComponent
        data={BikeTwoBeforeAfterImages}
        handleClick={handleClick}
      />
      {/* images Section */}
      <NormalImageContainer
        data={imagesFirstSection}
        handleClick={handleClick}
      />
      {/* second section */}
      <GridImageSection
        data={secondSection}
        handleClick={handleClick}
        baseUrl={imageUrl}
      />
      {/* second car before after */}
      <BeforeAfterComponent
        data={carBeforeAfterImages2}
        handleClick={handleClick}
      />
      <BeforeAfterComponent
        data={carBeforeAfterImages3}
        handleClick={handleClick}
      />
      {/* Third section */}
      <GridImageSection
        data={thirdSection}
        handleClick={handleClick}
        baseUrl={imageUrl}
      />
      <NormalImageContainer data={fourthSection} handleClick={handleClick} />
      {/* Zoom Effect */}
      <div>
      <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      aria-modal
      backgroundColor="transperent"
    >
      <ModalOverlay style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} />
      <ModalContent backgroundColor={"transparent"} boxShadow={"none"} padding={"0"}>
        <ModalHeader></ModalHeader>
        <div className="grid justify-items-end">
            <Button
              onClick={onClose}
              fontSize={"35px"}
              fontWeight={"bold"}
              backgroundColor={"transparent"}
              color={"white"}
              variant='link'
            >
              <IoClose/>
            </Button>
          </div>
        <ModalBody padding={{lg:"25px",md:"",base:"0"}}>
          <Swiper
            style={{
              "--swiper-navigation-size": "24px", // Set the default navigation size
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            zoom={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            initialSlide={specificIndex}
            modules={[Zoom, Navigation]}
            className="mySwiper"
            autoHeight={true}
          >
            {modalImage.map((image, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="w-[80%] lg:w-[90%] h-[800px] sm:w-full m-auto flex items-center justify-center">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          src={`${image}`}
                          alt="imag"
                          className={i===2 ? "w-[50%] block m-auto sm:w-full  h-full object-contain" : "w-[90%] block m-auto sm:w-full h-full object-contain"}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </ModalBody>
      </ModalContent>
    </Modal>
      </div>
    </div>
  );
};
