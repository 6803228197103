import React from "react";
import { Fade } from "react-reveal";
import { IFrameVideoContainer } from "../../../components/IFrameVideoContainer";

// const productVideos = [
//   "v1.mp4",
//   "v2.mp4",
//   "v3.mp4",
//   "v4.mp4",
//   "v5.mp4",
//   "v6.mp4",
// ];
const videosId = [
  "880281903",
  "880138695",
  "880138624",
  "880138673",
  "880138584",
  "880138792"
]
export const ProductVideo = () => {
  return (
    <div className="mt-20 sm:mt-5">
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              Product Videos
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              Our team specializes in creating compelling product videos that
              bring your offerings to life and drive customer engagement.
              <br />
              They provide a visual and engaging way to connect with your
              audience, explain how your product works, and highlight what sets
              it apart from the competition.
            </p>
          </Fade>
        </div>
      </div>
      <IFrameVideoContainer videoLinks={videosId} baseUrl={"https://player.vimeo.com/video/"}/>
    </div>
  );
};
