import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { CGI } from "./CGI";
import { Configuration } from "./Configuration";
import { ShootImages } from "./ShootImages";
import { Films} from "./Films";
import { ProductVideo } from "./ProductVideo";
import { Footer } from "../../../components/Footer";
import { TechnicalVideo } from "./TechnicalVideo";
import { Subnav } from "../../../components/subNav";
const Links = [
  {
    title: "CGI",
    page: <CGI />,
  },
  {
    title: "3D CONFIGURATOR",
    // href: "/3d-configurator",
    page: <Configuration />,
  },
  {
    title: "PHOTOGRAPHY",
    // href: "/shoot-images",
    page: <ShootImages />,
  },
  {
    title: "FILMS",
    // href: "/video-shoot",
    page: <Films/>,
  },

  {
    title: "PRODUCT VIDEOS",
    // href: "/product-video",
    page: <ProductVideo />,
  },
  {
    title: "TECHNICAL VIDEOS",
    // href: "/technical-video",
    page: <TechnicalVideo/>,
  },
];
const footerContent = [
  { title: "ABOUT US", href: "/aboutus" },
  { title: "CONTACT", href: "/contactus" },
];
export const Automobile = () => {
  const [currentPage, setCurrentPage] = useState(<CGI />);
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(
    1378
   );
   useEffect(() => {
     const handleResize = () => {
         setWindowWidth(window.innerWidth);
       };
     handleResize()
     if (typeof window !== "undefined") {
       window.addEventListener("resize", handleResize);
       return () => {
         window.removeEventListener("resize", handleResize);
       };
     } 
   }, []);

  //  useEffect(()=>{
  //   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //  },[])

  const handlePage = (page, index) => {
    setCurrentPage(page);
    setActiveIndex(index);
    window.scrollTo({top: 260, left: 0, behavior: 'smooth'});
  };
  return (
    <div className="pt-[80px]">
      <div className="relative">
        <img
          src={windowWidth>700 ? `../images/home/automobile/banner.jpg` : `../images/home/automobile/banner-mob.jpg`}
          alt="banner"
          className="w-full"
        />
        <Fade bottom>
          <p className="absolute w-full text-center top-1 font-[500] text-[90px] lg:text-[50px] text-white flex items-center justify-center h-full">
            AUTOMOBILE
          </p>
        </Fade>
      </div>
    
      {/* Sticky navbar */}
      <Subnav data={Links} handlePage={handlePage} activeIndex={activeIndex}/>

     {/* Pages Details */}
      <>{currentPage}</>
      {/* Footer */}
       <Footer data={footerContent}/>
    </div>
  );
};
