import React, { useState } from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Fade } from "react-reveal";
import { IFrameVideoContainer } from "../../../components/IFrameVideoContainer";

const images = [
  {
    image: "monte_caro_summer_01.jpg",
    hqImage: "monte_caro_summer_01_Hq.jpg",
  },
  {
    image: "monte_caro_summer_02.jpg",
    hqImage: "monte_caro_summer_02_Hq.jpg",
  },
  {
    image: "monte_caro_summer_03.jpg",
    hqImage: "monte_caro_summer_03_Hq.jpg",
  },
  {
    image: "monte_caro_summer_04.jpg",
    hqImage: "monte_caro_summer_04_Hq.jpg",
  },
  {
    image: "monte_caro_summer_05.jpg",
    hqImage: "monte_caro_summer_05_Hq.jpg",
  },
  {
    image: "monte_caro_summer_06.jpg",
    hqImage: "monte_caro_summer_06_Hq.jpg",
  },
  {
    image: "monte_caro_summer_07.jpg",
    hqImage: "monte_caro_summer_07_Hq.jpg",
  },
];

const videId = ["880286054"];
const modalImages = [
  "monte_caro_summer_01_Hq.jpg",
  "monte_caro_summer_02_Hq.jpg",
  "monte_caro_summer_03_Hq.jpg",
  "monte_caro_summer_04_Hq.jpg",
  "monte_caro_summer_05_Hq.jpg",
  "monte_caro_summer_06_Hq.jpg",
  "monte_caro_summer_07_Hq.jpg",
];
export const MonteCarloSummerDubai = () => {
  const imageUrl = "../images/home/lifestyle/"
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);

  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div>
      {/* Text content */}
      <div className="flex justify-between sm:grid">
        <div className="w-full pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Monte carlo summer dubai
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              {/* we create stunning graphics for the fashion and lifestyle
                industry, enhancing brand identities and marketing materials with
                style and creativity." */}
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1 mt-20 sm:mt-5">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
                key={i}
              >
                <img
                  src={`../images/home/lifestyle/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* video */}
      <IFrameVideoContainer
        videoLinks={videId}
        baseUrl={"https://player.vimeo.com/video/"}
      />

      <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen} //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  );
};
