import React from "react";
import { Fade } from "react-reveal";

export const BeforeAfterComponent = ({ data, handleClick }) => {
  return (
    <>
      {data.map((ele, i) => {
        return (
          <div className="flex">
            <Fade left>
              <div
                className="w-1/2 relative sm:h-[240px] cursor-pointer"
                key={i}
                onClick={() => handleClick(ele.HQBeforeimage)}
              >
                <img
                  src={ele.BeforeImage}
                  alt=""
                  className="w-full object-cover sm:h-full h-full"
                />
                 
                  <button className="bg-black text-white w-[15%] lg:w-[30%] lg:text-[24px] h-[35px] rounded-[25px] sm:w-[30%] sm:h-[30px] sm:text-[14px] sm:bottom-[10px] absolute bottom-[55px] border-white border-[0.5px] text-[18px] right-[30px]">
                    Before
                  </button>
                
              </div>
            </Fade>
            <Fade right>
              <div
                className="w-1/2 relative sm:h-[240px] cursor-pointer"
                key={i}
                onClick={() => handleClick(ele.HQAfterimage)}
              >
                <img
                  src={ele.AfterImage}
                  alt=""
                  className="w-full object-cover sm:h-full h-full"
                />

                <button className="bg-black text-white w-[15%] lg:w-[30%] lg:text-[24px] h-[35px] rounded-[25px] sm:w-[30%] sm:h-[30px] sm:text-[14px] sm:bottom-[10px] absolute bottom-[55px] border-white border-[0.5px] text-[18px] left-[30px]">
                  After
                </button>
              </div>
            </Fade>
          </div>
        );
      })}
    </>
  );
};
