import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FeaturedData = [
  {
    img: "img1.jpg",
    title: "AUTOMOBILE",
    href: "/featured/automobile",
  },
  {
    img: "img2.jpg",
    title: "LIFESTYLE",
    href: "/featured/lifestyle",
  },
  {
    img:
      "https://player.vimeo.com/video/880102634?background=1&muted=1&autoplay=1&loop=1&autopause=0",
    title: "TECH PRODUCT",
    href: "/featured/tech-product",
  },
  {
    img: "img4.jpg",
    title: "FMCG",
    href: "/featured/fmcg",
  },
  {
    img: "img5.jpg",
    title: "ARCHITECTURE",
    href: "/featured/architecture",
  },
  {
    img:
      "https://player.vimeo.com/video/880195790?background=1&muted=1&autoplay=1&loop=1&autopause=0",
    title: "CRAFT",
    href: "/featured/art",
  },
];

const footerContent = [
  { title: "ABOUT US", href: "/aboutus" },
  { title: "CONTACT", href: "/contactus" },
];
export const VideoHome = () => {
  const navigate = useNavigate();

  return (
    <div className="pt-[80px]">
      {FeaturedData.map((images, i) => {
        if (images.title === "TECH PRODUCT" || images.title === "CRAFT") {
          return (
            <div
              className="h-[700px] xl:h-full relative lg:h-fit sm:h-fit cursor-pointer object-cover overflow-hidden"
              key={i}
              onClick={() => navigate(images.href)}
            >
              <div className="pt-[55.25%] relative object-cover h-full">
                <iframe
                  src={images.img}
                  className="absolute top-0 left-0 w-[100%] h-[100%]"
                  title={images.title}
                />
              </div>
              <p className="text-white text-[90px] leading-[108px] lg:text-[70px] sm:text-[50px] text-center flex justify-center bg-[#00000080] items-center top-[0em] left-[0em] h-full w-full absolute font-[500] opacity-0 hover:opacity-[1] transition-[1s] duration-200 ease-in">
                {images.title}
              </p>
            </div>
          );
        } else {
          return (
            <div
              className="h-fit xl:h-full relative lg:h-fit sm:h-[300px] cursor-pointer object-cover"
              key={i}
              onClick={() => navigate(images.href)}
            >
              {/* <LazyLoadImage
                src={`./images/home/${images.img}`}
                alt="homePageimages"
                className="w-full sm:h-full block m-auto overflow-auto object-cover"
              /> */}
              <img
                src={`./images/home/${images.img}`}
                alt="banner"
                className="w-full sm:h-full block m-auto overflow-auto object-cover"
              />
              <p className="text-white text-[90px] leading-[108px] lg:text-[70px] sm:text-[50px] text-center flex justify-center bg-[#00000080] items-center top-[0em] left-[0em] h-full w-full absolute font-[500] opacity-0 hover:opacity-[1] transition-[1s] duration-200 ease-in">
                {images.title}
              </p>
            </div>
          );
        }
      })}
      <Footer data={footerContent} />
    </div>
  );
};
