import React from "react";
import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import Fade from "react-reveal";
import { Footer } from "../components/Footer";
const trustedBrandsImages = [
  "hyundai.png",
  "honda.png",
  "tata.png",
  "nissan.png",
  "renault.png",
  "toyota.png",
  "mahindra.png",
  "maruti.png",
  "force.png",
  "citroen.png",
];

const Articles = [
  {
    img: "articles.png",
    title: "8 Creative Ways to Repurpose Your Webinar Content",
    href: "",
  },
  {
    img: "articles.png",
    title: "8 Creative Ways to Repurpose Your Webinar Content",
    href: "",
  },
];

const services = [
  {
    icon: "icon1.png",
    title: "UX UI Design",
    description:
      "UI/UX design enhances digital product usability and user satisfaction through visually appealing, user-friendly interfaces.",
  },
  {
    icon: "icon2.png",
    title: "CGI and Animations",
    description:
      "Our multidisciplinary team of 3D artists and experts in CGI animation and more provides a comprehensive solution for all your project requirements.",
  },
  {
    icon: "icon3.png",
    title: "AR and VR",
    description:
      "Creating immersive digital environments for interaction, often via headsets, and overlaying digital content onto the real world, frequently used in mobile apps and wearable tech.",
  },
  {
    icon: "icon4.png",
    title: "Web Apps",
    description:
      "Developing dynamic web apps with enhanced features and responsive design for seamless cross-device accessibility.",
  },
  {
    icon: "icon5.png",
    title: "Print Media",
    description:
      "Designing captivating print materials, including brochures, posters, business cards, and magazines.",
  },
  {
    icon: "icon6.png",
    title: "Art Installation",
    description:
      "Our team creates innovative contemporary art installations and sculptures, partnering with top clients and achieving international acclaim.",
  },
];
const footerContent = [
  { title: "HOME", href: "/" },
  { title: "CONTACT", href: "/contactus" },
];
export const AboutUs = () => {
  return (
    <div className="pt-[80px]">
      <div className="flex items-center gap-10 lg:grid">
        <Fade bottom>
          <div className="w-[50%] lg:w-full">
            <img
              src="./images/aboutus/intro.jpg"
              alt="intro"
              className="w-full"
            />
          </div>
        </Fade>
        <Fade bottom cascade>
          <div className="w-[40%] lg:w-full lg:p-5">
            <p className="font-[500] text-[36px] sm:text-[24px]">
              <span className="text-[#676C6B]">Artificial</span>{" "}
              <span className="text-[#EC472C]">Reality</span> is a creative
              content production studio specializing in CGI, Print, Promotional
              Materials , Web Applications, Films and Videos.
            </p>
            <br />
            <p className="text-[24px] font-[500] sm:text-[16px]">
              Our success lies in our thorough understanding of the industry,
              combined with our focused approach and relentless pursuit of
              excellence.
            </p>
            <br />
            <p className="text-[24px] font-[500] sm:text-[16px]">
              Our team of skilled designers and artists meticulously craft
              high-quality 3D models and assets that capture the essence of your
              products.
            </p>
          </div>
        </Fade>
      </div>
      <div className="border-t-[#CEC9C8] border-b-[#CEC9C8] border-t-[1px] border-b-[1px] mt-20 relative">
        <p className="text-center text-[20px] sm:text-[16px] uppercase bg-white absolute bottom-[120px] xl:bottom-[100px] lg:bottom-[120px] sm:bottom-[110px] left-[45%] lg:left-[40%] sm:left-[32%] pr-5 pl-5">
          Trusted By{" "}
        </p>
        {/* brand swiper */}
        <div className="p-5 w-[80%] m-auto lg:w-full">
          <Swiper
            slidesPerView={4}
            loop={true}
            freeMode={true}
            allowTouchMove={true}
            autoplay={{ delay: 1, disableOnInteraction: false }}
            modules={[Autoplay, FreeMode]}
            speed={8000}
            breakpoints={{
              // Define breakpoints here
              1500: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 3,
              },
              900: {
                slidesPerView: 3,
              },
              600: {
                slidesPerView: 2,
              },
              480: {
                slidesPerView: 2,
              },
              450: {
                slidesPerView: 2,
              },
              375: {
                slidesPerView: 1,
              },
              360: {
                slidesPerView: 1,
              },
              280: {
                slidesPerView: 1,
              },
            }}
          >
            {trustedBrandsImages.map((image, i) => {
              return (
                <SwiperSlide className="" key={i}>
                  <div className="w-[30%]">
                    <img
                      src={`./images/aboutus/${image}`}
                      alt="BrandLogo"
                      className="block m-auto h-full w-full"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      {/* Our Services */}
      <div className="mt-20 flex justify-between p-10 lg:p-5 lg:pt-10 sm:mt-20 sm:grid w-full">
        <Fade bottom>
          <p className="text-[72px] font-500 w-[25%] lg:w-[40%] lg:text-[60px] lg:text-center sm:w-full sm:text-[36px] sm:text-left">
            Our Services
          </p>
        </Fade>
        <Fade bottom>
          <p className="text-[36px] w-[70%] font-[500] lg:text-[30px] lg:w-[60%] sm:w-full sm:text-[16px]">
            As a full-service agency, we provide comprehensive design and
            content solutions, emphasizing collaboration and pushing your
            boundaries to help you succeed with Faculty.
          </p>
        </Fade>
      </div>
      <div className="grid grid-cols-3 w-[90%] m-auto lg:grid-cols-2 lg:w-[95%] lg:mt-10 sm:grid-cols-1">
        {services.map((ele, i) => {
          return (
            <Fade bottom key={i}>
              <div className="border p-5 border-[#CEC9C8]">
                <div className="w-[10%] lg:w-[15%]">
                  <img
                    src={`./images/aboutus/${ele.icon}`}
                    alt="icon"
                    className="w-full"
                  />
                </div>
                <p className="text-[45px] font-[500] sm:text-[24px]">
                  {ele.title}
                </p>
                <p className="text-[24px] font-[500] mt-10 sm:text-[14px]">
                  {ele.description}
                </p>
              </div>
            </Fade>
          );
        })}
      </div>
      <Footer data={footerContent} />
    </div>
  );
};
