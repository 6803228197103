import React, { useState } from "react";
import { Fade } from "react-reveal";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,

} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation} from "swiper/modules";
import { IoClose } from "react-icons/io5";
const images = [
  {
    image: "nirav_modi_01.jpg",
    hqImage: "nirav_modi_01_Hq.jpg",
  },
  {
    image: "nirav_modi_02.jpg",
    hqImage: "nirav_modi_02_Hq.jpg",
  },
  {
    image: "nirav_modi_03.jpg",
    hqImage: "nirav_modi_03_Hq.jpg",
  },
  {
    image: "nirav_modi_04.jpg",
    hqImage: "nirav_modi_04_Hq.jpg",
  },
];

const modalImages = [
  "nirav_modi_01_Hq.jpg",
  "nirav_modi_02_Hq.jpg",
  "nirav_modi_03_Hq.jpg",
  "nirav_modi_04_Hq.jpg",
];
export const CraftSubSection_1 = () => {
  const imageUrl="../images/home/craft/"
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);

  const handleHomeimage = () => {
    onOpen();
    setHqImage("introHq.png");
  };
  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div className="mt-20 sm:mt-2">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              Nirav Modi
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/craft/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>
     
      {/* <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen}  //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      /> */}
      <div>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
          aria-modal
          backgroundColor="transperent"
        >
          <ModalOverlay style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} />
          <ModalContent
            backgroundColor={"transparent"}
            boxShadow={"none"}
            padding={"0"}
          >
            <ModalHeader></ModalHeader>
            <div className="grid justify-items-end">
              <Button
                onClick={onClose}
                fontSize={"35px"}
                fontWeight={"bold"}
                backgroundColor={"transparent"}
                color={"white"}
                variant="link"
              >
                <IoClose />
              </Button>
            </div>
            <ModalBody padding={{ lg: "25px", md: "", base: "0" }}>
              <Swiper
                style={{
                  "--swiper-navigation-size": "24px", // Set the default navigation size
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                zoom={true}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                initialSlide={specificIndex}
                modules={[Zoom, Navigation]}
                className="mySwiper"
                autoHeight={true}
              >
                {modalImages.map((image, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="w-[80%] lg:w-[90%] h-[800px] lg:h-full sm:h-full sm:w-full m-auto flex items-center justify-center">
                        <TransformWrapper>
                          <TransformComponent>
                            <img
                              src={`${imageUrl}${image}`}
                              alt="imag"
                              className={
                                i===1 ?
                                 "w-[55%] lg:w-full block m-auto sm:w-full  h-full object-contain" : "w-[75%] lg:w-full block m-auto sm:w-full  h-full object-contain"
                              }
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};
