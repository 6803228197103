
import React from "react";
import { Fade } from "react-reveal";

const configurator = [
  {
    image: "honda.jpg",
    logo: "honda-logo.png",
    text:
      "We developed a 3D car configurator tailored for Honda, offering a customized and immersive experience for their customers to explore and personalize their ideal vehicles.",
    href: "https://virtualshowroom.hondacarindia.com/#/car/hondacity",
  },
  {
    image: "renault.jpg",
    logo: "renault-logo.png",
    text:
      "We've also developed 3D car configurators for Renault, providing an engaging platform for customers to personalize their Renault vehicles.",
    href: "https://kiger.renault.co.in/kiger/#/car/kiger",
  },
  {
    image: "hyundai.jpg",
    logo: "hyndai-logo.png",
    text:
      "We designed a 3D configurator and expert call services for the Hyundai IONIQ5, offering a dynamic way for customers to personalize their vehicle and access expert assistance.",
    href: "https://ioniq5.artec.co.in/#/car/ioniq",
  },
];
export const Configuration = () => {
  return (
    <div className="mt-20 lg:mt-[20] sm:mt-5">
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              3D Configurator
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              We specialize in creating 3D car configurators, offering
              interactive tools that allow customers to design and customize
              their dream vehicles.
            </p>
          </Fade>
        </div>
      </div>
      <Fade bottom>
      <div className="mt-20 sm:mt-5">
        {configurator.map((ele, i) => {
          return (
            <div key={i} className="h-fit xl:h-full relative lg:h-[500px] sm:h-[300px] cursor-pointer object-cover">
                <img
                  src={`../images/home/automobile/config/${ele.image}`}
                  alt="car"
                  className="w-full h-full sm:h-full block m-auto overflow-auto object-cover"
                />
                <div className="absolute bottom-20 left-20 lg:left-10 sm:left-5 sm:bottom-5 grid gap-[15px]">
                  <div className="w-[10%] sm:w-[20%]">
                    <img src={`../images/home/automobile/config/${ele.logo}`} alt="" className="w-full"/>
                  </div>
                  <p className="text-white w-[60%] text-[24px] font-[500] lg:text-[20px] sm:text-[14px] sm:w-full">{ele.text}</p>
                  <a href={ele.href} target="blank"><button className="bg-white p-2 rounded-[30px] font-bold text-[18px] sm:text-[12px] sm:p-1 sm:rounded-[20px]">Visit Website</button></a>
                </div>
            </div>
          );
        })}
      </div>
      </Fade>
      
    </div>
  );
};
