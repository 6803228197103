import React from "react";
import { Fade } from "react-reveal";
import { IFrameVideoContainer } from "../../../components/IFrameVideoContainer";
// import { VideoComponent } from "../../../components/VideoComponent";


const videIds = [
  "880253924",
  "880253972",
  "880254013",
  "880254058",
  "880139563",
  "880139871",
  "880139901"
]

export const Films = () => {
  return (
    <div className="mt-20 sm:mt-5">
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Films
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              We're experts at creating captivating car videos that showcase
              vehicles in the best light. Our professional videographers bring
              out the beauty and features of cars, making them perfect for
              promotional purposes or informative content.
            </p>
          </Fade>
        </div>
      </div>
      <IFrameVideoContainer videoLinks={videIds} baseUrl={"https://player.vimeo.com/video/"}/>
    </div>
  );
};
