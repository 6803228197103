import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation } from "swiper/modules";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const ImageZoomModal = ({
  isOpen,
  onOpen,
  onClose,
  modalSize,
  specificIndex,
  data,
  baseUrl,
}) => {
  const OverlayOne = () => (
    <ModalOverlay style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} />
  );
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={modalSize}
      backgroundColor="transperent"
    >
      {overlay}
      <ModalContent
        backgroundColor={"transparent"}
        boxShadow={"none"}
        padding="0"
      >
        <ModalHeader></ModalHeader>
        {/* <ModalCloseButton
          backgroundColor={"transparent"}
          color={"white"}
          fontSize={"20px"}
          marginBottom={"100px"}
        /> */}
        <ModalBody padding={{lg:"25px",md:"",base:"0"}}>
          <div className="grid justify-items-end">
            <Button
              onClick={onClose}
              fontSize={"35px"}
              fontWeight={"bold"}
              backgroundColor={"transparent"}
              color={"white"}
              variant='link'
            >
              <IoClose />
            </Button>
          </div>

          <Swiper
            style={{
              "--swiper-navigation-size": "24px", // Set the default navigation size
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            zoom={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            initialSlide={specificIndex}
            modules={[Zoom, Navigation]}
            className="mySwiper"
            autoHeight={true}
          >
            {data.map((image, i) => {
              return (
                <SwiperSlide key={i} className="">
                  <div className="w-[80%] h-[800px] lg:h-full sm:h-full lg:w-[90%] sm:w-full m-auto flex items-center justify-center">
                    <TransformWrapper>
                      <TransformComponent className="w-full">
                        <img
                          src={`${baseUrl}${image}`}
                          alt="imag"
                          className="w-[90%] block m-auto sm:w-full object-cover"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
