import { useEffect, useState } from "react";
import { Navbar } from "./components/Navbar";
import { AllRoutes } from "./routes/AllRoutes";
import BarLoader from "react-spinners/BarLoader";
import Bounce from "react-reveal/Bounce";
// import CookieConsent from "react-cookie-consent";
import { Loader } from "./components/Loader";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (navigator.connection) {
      const { downlink } = navigator.connection;
      const estimatedLoadTime = 5000 / downlink; // Adjust the factor as needed

      setTimeout(() => {
        setLoading(false); // Set loading to false after the estimated load time
      }, estimatedLoadTime);
    } else {
      // Fallback in case navigator.connection is not supported
      setTimeout(() => {
        setLoading(false); // Set loading to false after a default delay
      }, 5000); // You can adjust the default delay as needed
    }
  }, []);

  return (
    <div
      className={
        loading
          ? "flex h-[100vh] justify-center items-center w-full"
          : "font-[syne-ttf] proportional-nums lining-nums scroll-smooth"
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="cookieconsent_status"
            style={{ background: "white", color: "black", height: "150px" }}
            buttonStyle={{
              color: "white",
              border: "1px solid #777C7B",
              borderRadius: "24px",
              fontSize: "16px",
              backgroundColor: "#777C7B",
              padding: "4px 16px 4px 16px",
              lineHeight: "19.6px",
            }}
            expires={150}
            enableDeclineButton
            declineButtonText="Reject"
            declineButtonStyle={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "24px",
              fontSize: "16px",
              border: "1px solid #777271",
              padding: "4px 16px 4px 16px",
              lineHeight: "19.6px",
            }}
            flipButtons
          >
            <p className="text-[18px] font-[400] leading-[18.2px] font-[syne]">
              We use cookies to ensure that we give you the best experience on
              our website.
            </p>
          </CookieConsent> */}
          <Navbar />
          <Bounce bottom>
            <AllRoutes />
          </Bounce>
        </>
      )}
    </div>
  );
}

export default App;
