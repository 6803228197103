import React, { useState } from "react";
import { Fade } from "react-reveal";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BsArrowRight } from "react-icons/bs";
const images = [
  {
    image: "img1.jpg",
    hqImage: "img1Hq.jpg",
  },
  {
    image: "img2.jpg",
    hqImage: "img2Hq.jpg",
  },
  {
    image: "img3.jpg",
    hqImage: "img3Hq.jpg",
  },
  {
    image: "img4.jpg",
    hqImage: "img4Hq.jpg",
  },
];

const modalImages = [
  "introHq.jpg",
  "img1Hq.jpg",
  "img2Hq.jpg",
  "img3Hq.jpg",
  "img4Hq.jpg",
];

export const TechImages = () => {
  const imageUrl = "../images/home/tech/images/";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);
  const handleHomeimage = () => {
    onOpen();
    setHqImage("introHQ.jpg");
    setSpecificIndex(0);
  };
  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div className="mt-20 sm:mt-5">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-2">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Images
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              We excel in capturing tech product images that showcase their
              features and design, helping you market and promote your
              innovations effectively.
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div
            className="w-[50%] sm:w-full cursor-pointer"
            onClick={handleHomeimage}
          >
            <img
              src="../images/home/tech/images/intro.jpg"
              alt="CGI"
              className="w-full"
            />
          </div>
        </Fade>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/tech/images/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* <ImageZoomModal
        onClose={onClose}
        isOpen={isOpen}
        modalSize={{ lg: "7xl", md: "4xl", base: "5xl", sm: "5xl" }}
      >

        <div className="w-full">
          <TransformWrapper>
            <TransformComponent className="w-full">
              <img
                src={`../images/home/tech/images/${currentHqImage}`}
                alt="test"
                className="w-full h-full object-cover"
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      </ImageZoomModal> */}
      <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen} //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  );
};
