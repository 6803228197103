import React, { useState } from "react";
import { Fade } from "react-reveal";
import { useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { GridImageSection } from "../../../components/GridImageSection";

const firstSectionImages = [
  {
    image: "img2.jpg",
    hqImage: "img2HQ.jpg",
  },
  {
    image: "img3.jpg",
    hqImage: "img3HQ.jpg",
  },
];
const secondSectionImages = [
  {
    image: "img4.jpg",
    hqImage: "img4HQ.jpg",
  },
  {
    image: "img5.jpg",
    hqImage: "img5HQ.jpg",
  },
];

const thirdSection = [
  {
    image: "img6.jpg",
    hqImage: "img6HQ.jpg",
  },
  {
    image: "img7.jpg",
    hqImage: "img7HQ.jpg",
  },
  {
    image: "img8.jpg",
    hqImage: "img8HQ.jpg",
  },
  {
    image: "img9.jpg",
    hqImage: "img9HQ.jpg",
  },
  {
    image: "img10.jpg",
    hqImage: "img10HQ.jpg",
  },
  {
    image: "img11.jpg",
    hqImage: "img11HQ.jpg",
  },
];

const fourthSection = [
  {
    image: "img12.jpg",
    hqImage: "img12HQ.jpg",
  },
  {
    image: "img13.jpg",
    hqImage: "img13HQ.jpg",
  },
];

const fifthSection = [
  {
    image: "img14.jpg",
    hqImage: "img14HQ.jpg",
  },
  {
    image: "img15.jpg",
    hqImage: "img15HQ.jpg",
  },
  {
    image: "img16.jpg",
    hqImage: "img16HQ.jpg",
  },
  {
    image: "img17.jpg",
    hqImage: "img17HQ.jpg",
  },
];
const sixthSection = [
  {
    image: "img18.jpg",
    hqImage: "img18HQ.jpg",
  },
  {
    image: "img19.jpg",
    hqImage: "img19HQ.jpg",
  },
];
const seventhSection = [
  {
    image: "img20.jpg",
    hqImage: "img20HQ.jpg",
  },
  {
    image: "img21.jpg",
    hqImage: "img21HQ.jpg",
  },
  {
    image: "img22.jpg",
    hqImage: "img22HQ.jpg",
  },
  {
    image: "img23.jpg",
    hqImage: "img23HQ.jpg",
  },
];

const modalImages = [
  "introHQ.jpg",
  "img2HQ.jpg",
  "img3HQ.jpg",
  "img4HQ.jpg",
  "img5HQ.jpg",
  "img6HQ.jpg",
  "img7HQ.jpg",
  "img8HQ.jpg",
  "img9HQ.jpg",
  "img10HQ.jpg",
  "img11HQ.jpg",
  "img12HQ.jpg",
  "img13HQ.jpg",
  "img14HQ.jpg",
  "img15HQ.jpg",
  "img16HQ.jpg",
  "img17HQ.jpg",
  "img18HQ.jpg",
  "img19HQ.jpg",
  "img20HQ.jpg",
  "img21HQ.jpg",
  "img22HQ.jpg",
  "img23HQ.jpg",
];

export const CGI = () => {
  const imageUrl = "../images/home/automobile/cgi/";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [specificIndex, setSpecificIndex] = useState(0);

  // HQ Image On Modal
  const handleClick = (ele) => {
    onOpen();
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };

  const handleHomeimage = () => {
    onOpen();
    setSpecificIndex(0);
  };

  const NormalImageContainer = ({ data, handleClick }) => {
    return (
      <div>
        {data.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="cursor-pointer"
                key={i}
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/automobile/cgi/${ele.image}`}
                  alt=""
                  className="w-full"
                />
              </div>
            </Fade>
          );
        })}
      </div>
    );
  };

  return (
    <div className="mt-20 sm:mt-5">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              CGI
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              Making CGI car images involves using computer technology to create
              realistic and good-looking car pictures. People use these images
              for ads, showing off products, and design presentations.
              <br />
              It's a flexible and creative way to showcase cars, much better
              than regular photos.
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div
            className="w-[50%] sm:w-full cursor-pointer"
            onClick={handleHomeimage}
          >
            <img
              src="../images/home/automobile/cgi/intro.jpg"
              alt="CGI"
              className="w-full"
            />
          </div>
        </Fade>
      </div>
      {/* First Section Images */}
      <GridImageSection
        data={firstSectionImages}
        handleClick={handleClick}
        baseUrl={imageUrl}
      />
      {/* Second section Images */}
      <NormalImageContainer
        data={secondSectionImages}
        handleClick={handleClick}
      />
      {/* Third section */}
      <GridImageSection
        data={thirdSection}
        handleClick={handleClick}
        baseUrl={imageUrl}
      />
      {/* Fourth Section */}
      <NormalImageContainer data={fourthSection} handleClick={handleClick} />
      {/* Fifth Section */}
      <GridImageSection
        data={fifthSection}
        handleClick={handleClick}
        baseUrl={imageUrl}
      />
      {/* Sixth section */}
      <NormalImageContainer data={sixthSection} handleClick={handleClick} />
      {/* seventh section */}
      <GridImageSection
        data={seventhSection}
        handleClick={handleClick}
        baseUrl={imageUrl}
      />

      {/* Modal */}
      <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen} //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  );
};
