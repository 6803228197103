import React from 'react'
import BarLoader from "react-spinners/BarLoader";

export const Loader = ({loading}) => {
  return (
    <div className='grid justify-items-center gap-2'>
        <div className='m-auto'>
          <img src="../images/logo-ar-in.png" alt="logo" className='w-[50%] block m-auto sm:w-[80%]'/>
        </div>
        <BarLoader
          color={"black"}
          loading={loading}
          size={1500}
          width={300}
          height={5}
          speedMultiplier={0.5}
        />
    </div>
  )
}
