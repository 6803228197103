import React, { useState } from "react";
import { Fade } from "react-reveal";
import {useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";


const images = [
  {
    image: "zoya_fairytale_01.jpg",
    hqImage: "zoya_fairytale_01_Hq.jpg",
  },
  {
    image: "zoya_fairytale_02.jpg",
    hqImage: "zoya_fairytale_02_Hq.jpg",
  }
];
const modalImages = [
  "zoya_fairytale_01_Hq.jpg",
  "zoya_fairytale_02_Hq.jpg"
]
export const CraftSubSection_6 = () => {
  const imageUrl="../images/home/craft/"
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);

  const handleHomeimage = () => {
    onOpen();
    setHqImage("introHq.png");
  };
  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div className="mt-20 sm:mt-2">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
                Zoya Fairytale
            </p>
          </Fade>
        </div>
        {/* <Fade bottom>
          <div
            className="w-[50%] sm:w-full cursor-pointer"
            onClick={handleHomeimage}
          >
            <img
              src="../images/home/art/intro.png"
              alt="CGI"
              className="w-full"
            />
          </div>
        </Fade> */}
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/craft/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen}  //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  );
};
