import React from "react";
// import { FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {GoArrowUpRight} from "react-icons/go"
// const footerContent = [
//   { title: "ABOUT US", href: "/aboutus" },
//   { title: "CONTACT", href: "/contactus" },
// ];

export const Footer = ({data}) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between m-auto mt-20">
      {data.map((ele, i) => {
        return (
          <div
            key={i}
            className={i===data.length-1 ? "w-1/2 grid justify-items-end border-r-[0px] border-t-[1px] border-b-[1px] cursor-pointer pr-5 border-[#cec9c8] text-[70px] lg:text-[50px] sm:text-[25px] sm:mt-0 sm:pt-0 sm:pb-5 hover:bg-[#777C7B] hover:text-white pb-[40px]" : "w-1/2 grid justify-items-end border-r-[1px] border-t-[1px] border-b-[1px] cursor-pointer pr-5 border-[#cec9c8] text-[70px] lg:text-[50px] sm:text-[25px] sm:mt-0 sm:pt-0 sm:pb-5 hover:bg-[#777C7B] hover:text-white pb-[40px]"}
            onClick={() => navigate(ele.href)}
          >
            <div className="flex items-center gap-1 sm:gap-0">
            <p>{ele.title}</p>
            <GoArrowUpRight />
            </div>
           
          </div>
        );
      })}
    </div>
  );
};
