import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Footer } from "../../../components/Footer";
import { useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation} from "swiper/modules";
import { IoClose } from "react-icons/io5";


const footerContent = [
  { title: "ABOUT US", href: "/aboutus" },
  { title: "CONTACT", href: "/contactus" },
];

const images = [
  {
    image: "jaquar_01.jpg",
    hqImage: "jaquar_01_Hq.jpg",
  },
  {
    image: "jaquar_02.jpg",
    hqImage: "jaquar_02_Hq.jpg",
  },
  {
    image: "jaquar_03.jpg",
    hqImage: "jaquar_03_Hq.jpg",
  },
  {
    image: "jaquar_04.jpg",
    hqImage: "jaquar_04_Hq.jpg",
  },
  {
    image: "jaquar_05.jpg",
    hqImage: "jaquar_05_Hq.jpg",
  },
  {
    image:"jaquar_queen_01.jpg",
    hqImage:"jaquar_queen_01_Hq.jpg"
  },
  {
    image:"jaquar_queen_02.jpg",
    hqImage:"jaquar_queen_02_Hq.jpg"
  },
  {
    image:"jaquar_queen_03.jpg",
    hqImage:"jaquar_queen_03_Hq.jpg"
  },
];
const modalImages = [
  "jaquar_01_Hq.jpg",
  "jaquar_02_Hq.jpg",
  "jaquar_03_Hq.jpg",
  "jaquar_04_Hq.jpg",
  "jaquar_05_Hq.jpg",
  "jaquar_queen_01_Hq.jpg",
  "jaquar_queen_02_Hq.jpg",
  "jaquar_queen_03_Hq.jpg"
];

export const Architecture = () => {
  const imageUrl = "../images/home/architecture/";
  const [windowWidth, setWindowWidth] = useState(1378);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  const handleClick = (ele) => {
    onOpen();
    // setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div className="pt-[80px]">
      <div className="relative">
        <img
          src={
            windowWidth > 700
              ? `../images/home/architecture/banner.jpg`
              : `../images/home/architecture/bannerMob.jpg`
          }
          alt="banner"
          className="w-full brightness-[0.5]"
        />
        <Fade bottom>
          <p className="absolute uppercase leading-[108px] sm:leading-[50px] w-full text-center top-1 font-[500] text-[90px] lg:text-[50px] text-white flex items-center justify-center h-full">
            architecture
          </p>
        </Fade>
      </div>
      <div className="flex justify-between sm:grid mt-20 sm:mt-5">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[30px]">
              Architecture
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              we provide architectural design and planning expertise,
              transforming concepts into functional and aesthetically pleasing
              structures.
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1 mt-20 sm:mt-2">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
                key={i}
              >
                <img
                  src={`../images/home/architecture/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>

      {/* <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen} //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      /> */}
      <div>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
          aria-modal
          backgroundColor="transperent"
        >
          <ModalOverlay style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} />
          <ModalContent
            backgroundColor={"transparent"}
            boxShadow={"none"}
            padding={"0"}
          >
            <ModalHeader></ModalHeader>
            <div className="grid justify-items-end">
              <Button
                onClick={onClose}
                fontSize={"35px"}
                fontWeight={"bold"}
                backgroundColor={"transparent"}
                color={"white"}
                variant="link"
              >
                <IoClose />
              </Button>
            </div>
            <ModalBody padding={{ lg: "25px", md: "", base: "0" }}>
              <Swiper
                style={{
                  "--swiper-navigation-size": "24px", // Set the default navigation size
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                zoom={true}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                initialSlide={specificIndex}
                modules={[Zoom, Navigation]}
                className="mySwiper"
                autoHeight={true}
              >
                {modalImages.map((image, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="w-[80%] lg:w-[90%] h-[800px] lg:h-full sm:h-full sm:w-full m-auto flex items-center justify-center">
                        <TransformWrapper>
                          <TransformComponent>
                            <img
                              src={`${imageUrl}${image}`}
                              alt="imag"
                              className={
                                i === 0 || i === 2 
                                  ? "w-[85%] lg:w-full block m-auto sm:w-full  h-full object-contain"
                                  : "w-[95%] lg:w-full block m-auto sm:w-full  h-full object-contain"
                              }
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
      <Footer data={footerContent} />
    </div>
  );
};
