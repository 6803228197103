import React, { useState } from 'react'
import { Button, useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Fade } from 'react-reveal';


const images = [
    {
      image: "reebook_01.jpg",
      hqImage: "reebook_01_Hq.jpg",
    },
    {
      image: "reebook_02.jpg",
      hqImage: "reebook_02_Hq.jpg",
    },
    {
      image: "reebook_03.jpg",
      hqImage: "reebook_03_Hq.jpg",
    },
    {
      image: "reebook_04.jpg",
      hqImage: "reebook_04_Hq.jpg",
    },
    {
      image: "reebook_05.jpg",
      hqImage: "reebook_05_Hq.jpg",
    },
    {
      image: "reebook_06.jpg",
      hqImage: "reebook_06_Hq.jpg",
    },
  ];
  const modalImages = [
    "reebook_01_Hq.jpg","reebook_02_Hq.jpg","reebook_03_Hq.jpg","reebook_04_Hq.jpg","reebook_05_Hq.jpg","reebook_06_Hq.jpg",
  ]
export const Reebok = () => {
  const imageUrl = "../images/home/lifestyle/"
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentHqImage, setHqImage] = useState("");
    const [specificIndex,setSpecificIndex] = useState(0)

    const handleClick = (ele) => {
        onOpen();
        setHqImage(ele);
        const index = modalImages.findIndex((image) => image === ele);
        setSpecificIndex(index);
      };
  return (
    <div>
        {/* Text content */}
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Reebok
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              {/* we create stunning graphics for the fashion and lifestyle
              industry, enhancing brand identities and marketing materials with
              style and creativity." */}
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1 mt-20 sm:mt-5">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
                key={i}
              >
                <img
                  src={`../images/home/lifestyle/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* <ImageZoomModal
        onClose={onClose}
        isOpen={isOpen}
        modalSize={{ lg: "5xl", md: "4xl", base: "5xl", sm: "5xl" }}
      >
        <div className="w-full">
          <TransformWrapper>
            <TransformComponent className="w-full">
              <img
                src={`../images/home/lifestyle/${currentHqImage}`}
                alt="test"
                className="w-full h-full object-cover"
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      </ImageZoomModal> */}
    <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen}  //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  )
}
