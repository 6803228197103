import React, { useState } from "react";
import {useDisclosure } from "@chakra-ui/react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Fade } from "react-reveal";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation} from "swiper/modules";
import { IoClose } from "react-icons/io5";

const images = [
  {
    image: "oasis_01.jpg",
    hqImage: "oasis_01_Hq.jpg",
  },
  {
    image: "oasis_02.jpg",
    hqImage: "oasis_02_Hq.jpg",
  },
  {
    image: "oasis_03.jpg",
    hqImage: "oasis_03_Hq.jpg",
  },
  {
    image: "oasis_04.jpg",
    hqImage: "oasis_04_Hq.jpg",
  },
  {
    image: "oasis_05.jpg",
    hqImage: "oasis_05_Hq.jpg",
  },
  {
    image: "oasis_07.jpg",
    hqImage: "oasis_07_Hq.jpg",
  },
];
const modalImages = [
  "oasis_01_Hq.jpg",
  "oasis_02_Hq.jpg",
  "oasis_03_Hq.jpg",
  "oasis_04_Hq.jpg",
  "oasis_05_Hq.jpg",
  "oasis_07_Hq.jpg"
]
export const Oasis = () => {
  const imageUrl = "../images/home/lifestyle/"
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex,setSpecificIndex] = useState(0)
  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
        setSpecificIndex(index);
  };
  return (
    <div>
      {/* Text content */}
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
             oasis
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1 mt-20 sm:mt-5">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
                key={i}
              >
                <img
                  src={`../images/home/lifestyle/${ele.image}`}
                  alt=""
                  className="w-full spotlight-target"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* custom Zoom Modal for this page*/}
      <div>
      <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      aria-modal
      backgroundColor="transperent"
    >
      <ModalOverlay style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} />
      <ModalContent backgroundColor={"transparent"} boxShadow={"none"} padding={"0"}>
        <ModalHeader></ModalHeader>
        <div className="grid justify-items-end">
            <Button
              onClick={onClose}
              fontSize={"35px"}
              fontWeight={"bold"}
              backgroundColor={"transparent"}
              color={"white"}
              variant='link'
            >
              <IoClose/>
            </Button>
          </div>
        <ModalBody padding={{lg:"25px",md:"",base:"0"}}>
          <Swiper
            style={{
              "--swiper-navigation-size": "24px", // Set the default navigation size
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            zoom={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            initialSlide={specificIndex}
            modules={[Zoom, Navigation]}
            className="mySwiper"
            autoHeight={true}
          >
            {modalImages.map((image, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="w-[80%] lg:w-[90%] h-[800px] lg:h-full sm:h-full sm:w-full m-auto flex items-center justify-center">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          src={`${imageUrl}${image}`}
                          alt="imag"
                          className={"w-[55%] lg:w-full block m-auto sm:w-full  h-full object-contain"}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </ModalBody>
      </ModalContent>
    </Modal>
      </div>
    </div>
  );
};
