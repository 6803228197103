import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Home } from "../pages/Home";
import { AboutUs } from "../pages/AboutUs";
import { ContactUs } from "../pages/ContactUs";
import { Automobile } from "../pages/featured/Automobile";
import { TechProducts } from "../pages/featured/Tech";
import { Lifestyle } from "../pages/featured/Lifestyle/Lifestyle";
import { FMCG } from "../pages/featured/fmcg";
import { Architecture } from "../pages/featured/Architecture";
import { Art } from "../pages/featured/Art";
import { VideoHome } from "../pages/VideoHome";


export const AllRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route element={<VideoHome/>} path="/"/>
      <Route element={<AboutUs />} path="/aboutus"/>
      <Route element={<ContactUs />} path="/contactus"/>
      <Route element={<Automobile/>} path="/featured/automobile"/>
      <Route element={<TechProducts/>} path="/featured/tech-product"/>
      <Route element={<Lifestyle/>} path="/featured/lifestyle"/>
      <Route element={<FMCG/>} path="/featured/fmcg"/>
      <Route element={<Architecture/>} path="/featured/architecture"/>
      <Route element={<Art/>} path="/featured/art"/>
    </Routes>
  );
};
