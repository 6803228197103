import React from "react";
import { Fade } from "react-reveal";
import { IFrameVideoContainer } from "../../../components/IFrameVideoContainer";
// import { VideoComponent } from "../../../components/VideoComponent";

// const techVideos = [
//   "v1.mp4",
//   "v2.mp4",
//   "v3.mp4",
// ];
const videoIds = [
  "880140285",
  "880140329",
  "880140467"
]
export const TechnicalVideo = () => {
  return (
    <div className="mt-20 sm:mt-5">
      <div className="flex justify-between sm:grid">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              Technical Videos
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              Our expertise lies in producing technical videos that simplify and
              communicate intricate information effectively.
              <br />
              They provide a clear and visual way to educate your audience on
              technical topics, making it easier for them to understand and
              learn.
            </p>
          </Fade>
        </div>
      </div>
      {/* Ifram video container */}
      <IFrameVideoContainer videoLinks={videoIds} baseUrl={"https://player.vimeo.com/video/"}/>
    </div>
  );
};
