import React, { useState } from "react";
import { Fade } from "react-reveal";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ImageZoomModal } from "../../../components/ImageZoomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { BsArrowRight } from "react-icons/bs";
const images = [
  {
    image: "maserati_01.jpg",
    hqImage: "maserati_01_Hq.jpg",
    isVideo: false
  },
  {
    image: "maserati_02.jpg",
    hqImage: "maserati_02_Hq.jpg",
    isVideo: false
  },
  {
    image: "maserati_03.jpg",
    hqImage: "maserati_03_Hq.jpg",
    isVideo: false
  },
  {
    image: "880141055",
    hqImage: "maserati_03_Hq.jpg",
    isVideo: false
  }
];

const modalImages = [
  "maserati_01_Hq.jpg",
  "maserati_02_Hq.jpg",
  "maserati_03_Hq.jpg"
]
export const CraftSubSection_2 = () => {
  const imageUrl="../images/home/craft/"
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);

  const handleHomeimage = () => {
    onOpen();
    setHqImage("introHq.png");
  };
  const handleClick = (ele) => {
    onOpen();
    setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  return (
    <div className="mt-20 sm:mt-2">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
                Maserati
            </p>
          </Fade>
        </div>
      </div>
      {/* images */}
      <div className="grid grid-cols-2 sm:grid sm:grid-cols-1">
        {images.map((ele, i) => {
          return (
            <Fade bottom>
            <div
              className="sm:w-full cursor-pointer"
              onClick={() => handleClick(ele.hqImage)}
            >
              <img
                src={`../images/home/craft/${ele.image}`}
                alt=""
                className="w-full spotlight-target"
              />
            </div>
          </Fade>
          );
        })}
      </div>
      <div className="mt-20 sm:mt5">
          <div className="pt-[55.25%] relative object-cover h-full">
          <iframe
            src={`https://player.vimeo.com/video/880141055`}
            allow="fullscreen"
            className="absolute top-0 left-0 w-[100%] h-[100%]"
            title="video shoot"
          />
        </div>
      </div>
      <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen}  //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      />
    </div>
  );
};
