import React from "react";
import { Fade } from "react-reveal";
import { IFrameVideoContainer } from "../../../components/IFrameVideoContainer";



const videoIds = ["880140822", "880140784"];
export const TechVideos = () => {
  return (
    <div className="mt-20px">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Videos
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#777271] lg:w-full lg:text-[16px] sm:text-[14px]">
              Product videos offer a dynamic way to showcase your offerings,
              providing a visual and engaging experience for your audience.
            </p>
          </Fade>
        </div>
      </div>
      {/* Videos */}
      <IFrameVideoContainer
        videoLinks={videoIds}
        baseUrl={"https://player.vimeo.com/video/"}
      />
    </div>
  );
};
