import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Footer } from "../../../components/Footer";
import { TechImages } from "./Images";
import { TechVideos } from "./Videos";
import { Subnav } from "../../../components/subNav";

const footerContent = [
    { title: "ABOUT US", href: "/aboutus" },
    { title: "CONTACT", href: "/contactus" },
  ];
const techNav = [
  {
    title: "IMAGES",
    page:<TechImages/>,
  },
  {
    title: "VIDEO",
    page:<TechVideos/>,
  },
];
export const TechProducts = () => {
  const [currentPage, setCurrentPage] = useState(<TechImages/>);
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(1378);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const handlePage = (page, index) => {
    setCurrentPage(page);
    setActiveIndex(index);
    window.scrollTo({top:0, left: 0, behavior: 'smooth'});
  };
  return (
    <div className="pt-[80px]">
      <div className="relative">
        <img
          src={
            windowWidth > 700
              ? `../images/home/tech/techBanner.jpg`
              : `../images/home/tech/bannerMob.jpg`
          }
          alt="banner"
          className="w-full brightness-[0.5]"
        />
        <Fade bottom>
          <p className="absolute uppercase leading-[108px] sm:leading-[50px] w-full text-center top-1 font-[500] text-[90px] lg:text-[50px] text-white flex items-center justify-center h-full">
            Tech Products
          </p>
        </Fade>
      </div>
      {/* tech nav */}
      <Subnav data={techNav} activeIndex={activeIndex} handlePage={handlePage}/>
      <>{currentPage}</>
      {/* Footer */}
       <Footer data={footerContent}/>
    </div>
  );
};
