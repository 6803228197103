import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Footer } from "../../../components/Footer";
import { Reebok } from "./Reebok";
import { Yousta } from "./Yousta";
import { Oasis } from "./Oasis";
import { MonteCarlo } from "./MonteCarlo";
import { MonteCarloSummerDubai } from "./MonteCarloSummerDubai";
import { ApolloTyre } from "./ApolloTyre";
import { Subnav } from "../../../components/subNav";

const footerContent = [
  { title: "ABOUT US", href: "/aboutus" },
  { title: "CONTACT", href: "/contactus" },
];
const subLinks = [
  {
    title: "Reebok",
    page: <Reebok />,
  },
  {
    title: "Yousta",
    page: <Yousta />,
  },
  {
    title: "Oasis",
    page: <Oasis />,
  },
  {
    title: "Monte Carlo A/W 2019",
    page: <MonteCarlo />,
  },
  {
    title: "Monte Carlo Summer Dubai",
    page: <MonteCarloSummerDubai />,
  },
  {
    title: "Apollo Tyre",
    page: <ApolloTyre />,
  },
];
export const Lifestyle = () => {
  const [windowWidth, setWindowWidth] = useState(1378);
  const [currentPage, setCurrentPage] = useState(<Reebok />);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  const handlePage = (page, index) => {
    setCurrentPage(page);
    setActiveIndex(index);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="pt-[80px]">
      <div className="relative">
        <img
          src={
            windowWidth > 700
              ? `../images/home/lifestyle/banner.jpg`
              : `../images/home/lifestyle/bannerMob.jpg`
          }
          alt="banner"
          className="w-full brightness-[0.5]"
        />
        <Fade bottom>
          <p className="absolute uppercase leading-[108px] sm:leading-[50px] w-full text-center top-1 font-[500] text-[90px] lg:text-[50px] text-white flex items-center justify-center h-full">
            lifestyle
          </p>
        </Fade>
      </div>

      {/* Sticky nav */}
      <Subnav
        data={subLinks}
        activeIndex={activeIndex}
        handlePage={handlePage}
      />
      {/* Current Page */}
      {currentPage}
      <Footer data={footerContent} />
    </div>
  );
};
