import React from "react";
import { Fade } from "react-reveal";

const videoIds = ["880269383", "880269426"];

export const CraftSubSection_4 = () => {
  return (
    <div className="mt-20px">
      <div className="flex justify-between sm:grid ">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] uppercase font-[500] lg:text-[60px] sm:text-[36px]">
              Zoya – Musee Du Luxe
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div className="w-[50%] sm:w-full cursor-pointer"></div>
        </Fade>
      </div>
      <div className="mt-20 sm:mt5">
        {videoIds.map((link) => {
          return (
            <div className="pt-[55.25%] relative object-cover h-full">
              <iframe
                src={`https://player.vimeo.com/video/${link}`}
                allow="fullscreen"
                className="absolute top-0 left-0 w-[100%] h-[100%]"
                title="video shoot"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
