import React from 'react'

export const IFrameVideoContainer = ({videoLinks,baseUrl}) => {
  return (
    <div className="mt-0 sm:mt5">
        {videoLinks.map((id) => {
          return (
            <div className="pt-[55.25%] relative object-cover h-full">
          <iframe
            src={`${baseUrl}${id}`}
            allow="fullscreen"
            className="absolute top-0 left-0 w-[100%] h-[100%]"
            title="video shoot"
          />
        </div>
          );
        })}
      </div>
  )
}
