import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Footer } from "../../../components/Footer";
import { Button, useDisclosure } from "@chakra-ui/react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation } from "swiper/modules";
import { IoClose } from "react-icons/io5";
const footerContent = [
  { title: "ABOUT US", href: "/aboutus" },
  { title: "CONTACT", href: "/contactus" },
];

const imageSecitonOne = [
  {
    image: "img1.jpg",
    hqImage: "img1HQ.jpg",
  },
  {
    image: "img2.jpg",
    hqImage: "img2HQ.jpg",
  },
];

const sectionTwoImages = [
  {
    image: "img3HQ.jpg",
    hqImage: "img3HQ.jpg",
  },
  {
    image: "img4HQ.jpg",
    hqImage: "img4HQ.jpg",
  },
  {
    image: "img5HQ.jpg",
    hqImage: "img5HQ.jpg",
  },
  {
    image: "img6HQ.jpg",
    hqImage: "img6HQ.jpg",
  },
  {
    image: "img7HQ.jpg",
    hqImage: "img7HQ.jpg",
  },
  {
    image: "img8HQ.jpg",
    hqImage: "img8HQ.jpg",
  },
];
const imageSecitonThird = [
  {
    image: "img9.jpg",
    hqImage: "img9HQ.jpg",
  },
  {
    image: "img10.jpg",
    hqImage: "img10HQ.jpg",
  },
];
const imageSecitonFourth = [
  {
    image: "img11.jpg",
    hqImage: "img11HQ.jpg",
  },
  {
    image: "jack_02HQ.jpg",
    hqImage: "jack_02HQ.jpg",
  },
  {
    image: "jack01HQ.jpg",
    hqImage: "jack01HQ.jpg",
  },
];
const modalImages = [
  "introHQ.jpg",
  "img1HQ.jpg",
  "img2HQ.jpg",
  "img3HQ.jpg",
  "img4HQ.jpg",
  "img5HQ.jpg",
  "img6HQ.jpg",
  "img7HQ.jpg",
  "img8HQ.jpg",
  "img9HQ.jpg",
  "img11HQ.jpg",
  "jack_02HQ.jpg",
  "jack01HQ.jpg",
];

export const FMCG = () => {
  const imageUrl = "../images/home/fmcg/";
  const [windowWidth, setWindowWidth] = useState(1378);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [currentHqImage, setHqImage] = useState("");
  const [specificIndex, setSpecificIndex] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const handleClick = (ele) => {
    onOpen();
    // setHqImage(ele);
    const index = modalImages.findIndex((image) => image === ele);
    setSpecificIndex(index);
  };
  const handleHomeimage = () => {
    onOpen();
    // setHqImage("introHQ.png");
    setSpecificIndex(0);
  };
  return (
    <div className="pt-[80px]">
      <div className="relative">
        <img
          src={
            windowWidth > 700
              ? `../images/home/fmcg/banner.jpg`
              : `../images/home/fmcg/bannerMob.jpg`
          }
          alt="banner"
          className="w-full brightness-[0.5]"
        />
        <Fade bottom>
          <p className="absolute uppercase leading-[108px] sm:leading-[50px] w-full text-center top-1 font-[500] text-[90px] lg:text-[50px] text-white flex items-center justify-center h-full">
            F M C G
          </p>
        </Fade>
      </div>
      <div className="flex justify-between mt-20 sm:grid sm:mt-2">
        <div className="w-[50%] pl-10 sm:w-full sm:p-5">
          <Fade bottom>
            <p className="text-[74px] font-[500] lg:text-[60px] sm:text-[36px]">
              Image
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-[24px] font-[500] w-full text-[#322C2B] lg:w-full lg:text-[16px] sm:text-[14px]">
              We specialize in designing graphics for Fast-Moving Consumer Goods
              (FMCG) products, helping brands make their packaging and marketing
              materials visually appealing and effective
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div
            className="w-[50%] sm:w-full cursor-pointer"
            onClick={handleHomeimage}
          >
            <img
              src="../images/home/fmcg/intro.jpg"
              alt="CGI"
              className="w-full"
            />
          </div>
        </Fade>
      </div>
      <div className="flex sm:grid">
        {imageSecitonOne.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                key={i}
                className="w-1/2 sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/fmcg/${ele.image}`}
                  alt="img"
                  className="w-full"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* Second section Images */}
      <div>
        {sectionTwoImages.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="cursor-pointer"
                key={i}
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/fmcg/${ele.image}`}
                  alt=""
                  className="w-full"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* third section */}
      <div className="flex sm:grid">
        {imageSecitonThird.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                key={i}
                className="w-1/2 sm:w-full cursor-pointer"
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/fmcg/${ele.image}`}
                  alt="img"
                  className="w-full"
                />
              </div>
            </Fade>
          );
        })}
      </div>
      {/* Fourth section */}
      <div>
        {imageSecitonFourth.map((ele, i) => {
          return (
            <Fade bottom>
              <div
                className="cursor-pointer"
                key={i}
                onClick={() => handleClick(ele.hqImage)}
              >
                <img
                  src={`../images/home/fmcg/${ele.image}`}
                  alt=""
                  className="w-full"
                />
              </div>
            </Fade>
          );
        })}
      </div>

      {/* <ImageZoomModal
        data={modalImages} //hQ image Array
        baseUrl={imageUrl} //base url of image
        specificIndex={specificIndex} //image to be shown
        onClose={onClose} //close the modal
        isOpen={isOpen} //open the modal
        modalSize={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
      /> */}
      <div>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size={{ lg: "", md: "4xl", base: "5xl", sm: "full" }}
          aria-modal
          backgroundColor="transperent"
        >
          <ModalOverlay style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }} />
          <ModalContent
            backgroundColor={"transparent"}
            boxShadow={"none"}
            padding={"0"}
          >
            <ModalHeader></ModalHeader>
            <div className="grid justify-items-end">
              <Button
                onClick={onClose}
                fontSize={"35px"}
                fontWeight={"bold"}
                backgroundColor={"transparent"}
                color={"white"}
                variant="link"
              >
                <IoClose />
              </Button>
            </div>
            <ModalBody padding={{ lg: "25px", md: "", base: "0" }}>
              <Swiper
                style={{
                  "--swiper-navigation-size": "24px", // Set the default navigation size
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                zoom={true}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                initialSlide={specificIndex}
                modules={[Zoom, Navigation]}
                className="mySwiper"
                autoHeight={true}
              >
                {modalImages.map((image, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="w-[80%] lg:w-[90%] h-[800px] lg:h-full sm:h-full sm:w-full m-auto flex items-center justify-center">
                        <TransformWrapper>
                          <TransformComponent>
                            <img
                              src={`${imageUrl}${image}`}
                              alt="imag"
                              className={
                                i === 0 || i === 2 
                                  ? "w-[55%] lg:w-full block m-auto sm:w-full  h-full object-contain"
                                  : "w-[95%] lg:w-full block m-auto sm:w-full  h-full object-contain"
                              }
                            />
                          </TransformComponent>
                        </TransformWrapper>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
      <Footer data={footerContent} />
    </div>
  );
};
